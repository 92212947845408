@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

body {
    background-color: #F7FAFF;
}
body {
    font-family: 'Poppins', sans-serif;
}
